<template>
  <div class="row">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="password"
              :rules="passVal"
              name="The Password"
              v-slot="{ passed, failed,errors }"
            >
              <fg-input type="password"
                        :error="failed ? errors[0]: null"
                        :hasSuccess="passed"
                        :label="$store.getters['languageMixin/getStringFromLanguage']('Password')"
                        v-model="formData.password">
              </fg-input>
            </ValidationProvider>

            <ValidationProvider
              rules="required|confirmed:password"
              name="The Confirmed Password"
              v-slot="{ passed, failed ,errors}">
              <fg-input type="password"
                        :error="failed ? errors[0]: null"
                        :hasSuccess="passed"
                        :label="$store.getters['languageMixin/getStringFromLanguage']('Confirm Password')"
                        name="confirm"
                        v-model="formData.confirmed_password">
              </fg-input>

            </ValidationProvider>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="submitting" @click.prevent="handleSubmit(submit)" nativeType="submit"
                      type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
            </l-button>&nbsp;
            <l-button @click="$router.push('/users/list')" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Cancel') }}
            </l-button>
          </div>
        </card>
      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {required, confirmed, min, regex} from "vee-validate/dist/rules";

extend("confirmed", confirmed)
extend("required", required);
extend("min",{...min,message:'the password must be 8 character or more'});
extend("regex",{...regex,message:'Password requires 1 of each of the following: uppercase letter, lowercase letter, number.'});


export default {

  data() {
    return {
      formTitle: "Change Password",
      submitting: false,
      formData: {
        password: "",
        confirmed_password: "",
      },
      passVal:{required: true,min: 8,regex: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$' }
    };
  },


  methods: {
    submit() {
      this.submitting = true;
      this.axios.post("users/change-password/" + this.$route.params['id'], this.formData)
        .then((response) => {
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Password Updated Successfully"),
            timeout: 1000,
            type: 'success'
          })
          this.$router.push("/users/list");
        }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      });
    }
  }
}
</script>

<style>
</style>
